<template>
  <div>
    <custom-header :status="isBankSlipFailure" />
    <bank-slip-failure
      v-if="isBankSlipFailure"
      :action-back-to-bank-slip-payment="displayBankSlipPaymentCheckout"
      :bank-slip-failure-message="bankSlipFailureMessage"
    />

    <div v-if="!isBankSlipFailure" class="checkout page" :style="plansHeight">
      <form name="checkout-form" class="checkout-form" novalidate="true" @submit.prevent>
        <div class="checkout-form-body">
          <div id="planInfo" class="plan-info expanded" @click="calculatePlansHeight">
            <div class="section-title open">
              <p class="primary">Confira o seu plano:</p>
              <div class="secondary" @click="planOffers.show = !planOffers.show">
                <p v-if="userInfo" class="user-email" id="boleto-userEmail">
                  {{ userInfo.email }}
                </p>
              </div>
            </div>
            <div v-if="planOffer" class="plans-container open">
              <div v-for="plan in availablePlans" :key="plan.id" class="plan" :class="{ discount: discountApplied }">
                <input :id="plan.id" v-model="form.plan.value" type="radio" name="plan" :value="plan.id" />
                <label
                  :for="plan.id"
                  class="plan-card"
                  :class="plan.class"
                  :data-discount-percentage="`-${
                    discountApplied ? plan.price.discountPercentage : plan.price.originalDiscountPercentage
                  }%`"
                >
                  <p class="plan-title">
                    <span class="period">{{ plan.title.period }}</span>
                    <span class="type">{{ plan.title.type }}</span>
                  </p>
                  <p class="price" :class="{ discount: discountApplied }">
                    <span class="full">De R$ {{ numberToCurrency(plan.price.original) }}</span>
                    <span> | </span>
                    <span :class="{ 'with-coupon-discount-applied': couponDiscountApplied }">
                      <span class="withDiscount"
                        >Por R$ {{ numberToCurrency(discountApplied ? plan.price.discount : plan.price.full) }}</span
                      >
                    </span>
                  </p>
                </label>
              </div>
            </div>
            <div class="submit-form mobile">
              <custom-button :primary="true" :action="submitSubscription"> Finalizar pagamento </custom-button>
            </div>
          </div>

          <div class="container">
            <h2 class="title-form">Falta pouco!</h2>
            <h3 class="subtitle-form">Insira abaixo seus dados pessoais para gerar o boleto:</h3>
            <img src="../../assets/images/svg/boleto.svg" alt="Pagamento por boleto" class="boleto-icon" />
            <p class="iugu">
              Pagamentos por
              <img src="../../assets/images/svg/iugu.svg" alt="Pagamentos por iugu" />
            </p>
            <div class="payment-info">
              <div class="info-container">
                <form-input
                  :name="'fullName'"
                  :value="form.fullName.value"
                  @input="form.fullName.value = $event"
                  @blur="
                    trackSnowplow('sendSnowplowSubscribeEvent', {
                      form_element: form.fullName,
                      step_name: 'user_full_name',
                      step_num: 10,
                    })
                  "
                />
                <form-input
                  :name="'cpf'"
                  :disabled="form.cpf.disabled"
                  :value="form.cpf.value"
                  @blur="
                    trackSnowplow('sendSnowplowSubscribeEvent', {
                      form_element: form.cpf,
                      step_name: 'user_cpf',
                      step_num: 11,
                    })
                  "
                />
                <form-input
                  :name="'zipCode'"
                  :value="form.zipCode.value"
                  @input="form.zipCode.value = $event"
                  @blur="fillAddress(form.zipCode)"
                />
                <form-input
                  :name="'street'"
                  :value="form.street.value"
                  @input="form.street.value = $event"
                  @blur="
                    trackSnowplow('sendSnowplowSubscribeEvent', {
                      form_element: form.street,
                      step_name: 'address_street',
                      step_num: 14,
                    })
                  "
                />
                <div class="row">
                  <form-input
                    :name="'number'"
                    :value="form.number.value"
                    @input="form.number.value = $event"
                    @blur="
                      trackSnowplow('sendSnowplowSubscribeEvent', {
                        form_element: form.number,
                        step_name: 'address_number',
                        step_num: 15,
                      })
                    "
                  />
                  <form-input
                    :name="'complement'"
                    :value="form.complement.value"
                    @input="form.complement.value = $event"
                    @blur="
                      trackSnowplow('sendSnowplowSubscribeEvent', {
                        form_element: form.complement,
                        step_name: 'address_complement',
                        step_num: 16,
                      })
                    "
                  />
                </div>
                <div class="discount-coupon">
                  <form-input
                    v-if="planOffer && planOffer.acceptCoupon"
                    :name="'discountCoupon'"
                    :value="form.discountCoupon.value"
                    :disabled="form.discountCoupon.disabled"
                    :readonly="form.discountCoupon.readonly"
                    :change="handleCouponValue"
                    @input="form.discountCoupon.value = $event"
                  />
                  <button
                    v-if="!discountApplied && !form.discountCoupon.readonly"
                    type="button"
                    @click="applyDiscountCoupon(form.discountCoupon.value)"
                  >
                    Aplicar
                  </button>
                  <button
                    v-if="discountApplied && !form.discountCoupon.readonly"
                    type="button"
                    @click="removeDiscountApplied(form.discountCoupon)"
                  >
                    X
                  </button>
                </div>
                <div class="discount-coupon-info">Desconto não acumulativo. Ao aplicar valerá como o principal.</div>
              </div>
            </div>

            <div class="purchase-summary">
              <div class="summary">
                <div>
                  <p>Resumo da compra</p>
                </div>
                <div :key="summaryPlan.id" v-for="summaryPlan in applySummary">
                  <p class="summary-price">
                    <span class="full">R${{ numberToCurrency(summaryPlan.price.original) }}</span> |
                    <span v-if="discountApplied">R${{ numberToCurrency(summaryPlan.price.discount) }}</span>
                    <span v-else>R${{ numberToCurrency(summaryPlan.price.full) }}</span>
                  </p>
                </div>
              </div>

              <div class="checkout-form-button">
                <custom-button :primary="true" :action="submitSubscription"> Finalizar pagamento </custom-button>
              </div>
            </div>

            <div class="checkout-form-button-desktop">
              <custom-button :primary="true" :action="submitSubscription"> Finalizar pagamento </custom-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import FormInput from '@/components/FormInput';
import SubscriptionService from '@/services/SubscriptionService';
import OfferService from '@/services/OfferService';
import ZipCodeService from '@/services/zip-code';
import Utils from '@/assets/js/Utils';
import CustomHeader from '@/components/CustomHeader';
import UserSessionService from '@/services/user-session';

import LanguageString from '@/config/language/pt-BR';

import * as Sentry from '@sentry/browser';
import bankSlipFailure from '@/views//bank-slip-failure/bank-slip-failure.vue';
import constants from '@/constants';

export default {
  name: 'CheckoutBankSlip',
  components: {
    'custom-button': CustomButton,
    'form-input': FormInput,
    'custom-header': CustomHeader,
    'bank-slip-failure': bankSlipFailure,
  },
  data() {
    return {
      windowWidth: 0,
      plansHeight: { '--plans-height': '0px' },
      aboutInfo: {
        show: false,
        text: 'Será feito uma pré-autorização no seu cartão e você verá duas cobranças com o valor do plano escolhido por você. Mas não se preocupe! Uma delas será cancelada e você não será cobrado duas vezes. ',
      },
      couponDiscountApplied: false,
      discountApplied: false,
      discountPercentage: 0,
      discountCouponValue: null,
      basePrice: process.env.VUE_APP_BASE_PRICE,
      planOffer: null,
      planOffers: {},
      userSession: null,
      isBankSlipFailure: false,
      bankSlipFailureMessage: null,
      languageString: LanguageString,
      summaryPlanId: null,

      form: {
        fullName: {
          value: '',
          disabled: false,
        },
        cpf: {
          value: '',
          disabled: false,
        },
        zipCode: {
          value: '',
          disabled: false,
        },
        street: {
          value: '',
          disabled: false,
        },
        number: {
          value: '',
          disabled: false,
        },
        complement: {
          value: '',
          disabled: false,
        },
        plan: {
          value: '',
        },
        discountCoupon: {
          value: '',
          disabled: false,
        },
      },
    };
  },
  computed: {
    notSplittablePlan() {
      return this.planOffer.options.find(item => !item.isSplittable);
    },
    userInfo() {
      return this.userSession ? this.userSession.currentUser : null;
    },
    availablePlans() {
      if (this.planOffer) {
        return this.planOffer.options.filter(plan => plan.id === this.notSplittablePlan.id);
      }
    },
    applySummary() {
      if (this.planOffer) {
        return this.planOffer.options.filter(plan => plan.id === this.summaryPlanId);
      }
    },
    handleCouponValue() {
      const hasError = document.querySelector('input[name=discount-coupon]');

      if
      ((this.form.discountCoupon.value === '' ||
        this.form.discountCoupon.value.length < 10) &&
        hasError !== null
      ) {
        document.querySelector('.discount-coupon').classList.remove('form-input-error');
        document.querySelector('.discount-coupon-info').classList.remove('discount-coupon-invalid');
        document.querySelector('.discount-coupon-info').textContent = `Desconto não acumulativo. Ao aplicar valerá como o principal.`;
      }
    },
  },
  async created() {
    if (UserSessionService.isSubscriber()) {
      this.$router.push({
        name: 'subscription-info',
        query: this.$route.query,
      });
      return;
    }

    try {
      this.userSession = UserSessionService.getData() || UserSessionService.getAuthenticationCookieData();

      this.trackSnowplow('trackButton', {
        button_name: 'payment_method',
        additional_properties: 'bank_slip',
      });

      const offersData = (await SubscriptionService.getOffersByGateway()).data;

      this.planOffers = {
        show: false,
        offers: {
          default: {
            isBlackFriday: offersData.backgroundImage === 'black_friday',
            acceptCoupon: offersData.plans.list[1].acceptsCoupon,
            discountCouponCode: offersData.plans.list[1].discountCouponCode,
            options: [],
          },
        },
      };

      const planClasses = ['first-card', 'second-card'];

      for (let key in offersData.plans.list) {
        const currentItem = offersData.plans.list[key];
        const fullPrice = this.currencyToNumber(offersData.plans.basePrice.annually);
        const priceWithBaseDiscount = fullPrice - (fullPrice / 100) * currentItem.baseDiscountPercentage;
        const item = {
          id: currentItem.storeId,
          name: `Anual ${currentItem.subtitle.text1.toLowerCase()}`,
          class: planClasses[key],
          title: {
            period: currentItem.title,
            type: currentItem.subtitle.text1,
          },
          isSplittable: currentItem.splittable,
          price: {
            original: this.currencyToNumber(offersData.plans.basePrice.annually),
            full: priceWithBaseDiscount,
            discountPercentage: currentItem.discountPercentage,
            originalDiscountPercentage: Math.round(currentItem.baseDiscountPercentage),
          },
        };

        this.planOffers.offers.default.options.push(item);
      }

      this.form.plan.value = this.$route.query.planid || offersData.plans.list.find(plan => !plan.splittable)?.storeId;
      this.planOffer = this.getPlanOfferByPlan(this.form.plan.value);

      this.setCPF();
      this.applyDiscountCoupon(this.$route.query.couponCode || this.planOffers.offers.default.discountCouponCode);

      this.$nextTick(() => {
        if (window && window.hasOwnProperty('addEventListener')) {
          window.addEventListener('resize', this.getWindowWidth);
        }

        this.getWindowWidth();
      });
      this.summaryPlanId = this.form.plan.value;
      this.trackBrazeWhenLoadPage();
    } catch (err) {
      if (err.response) {
        err = err.response.data;
      }

      let errorMessage = err.message || err.mensagem;

      if (errorMessage) {
        if (
          err.code === 'AUTH_CODE_EXPIRED' ||
          err.code === 'AUTH_INVALID_AUTH_CODE' ||
          err.code === 'AUTH_WRONG_TOKEN_TYPE'
        ) {
          UserSessionService.removeData();
          this.createToast(errorMessage, {
            redirectTo: 'login',
          });
        } else if (errorMessage.includes('getAuthenticationCookieData')) {
          UserSessionService.removeData();
          this.createToast('Desculpe, não encontramos a sua sessão. Efetue o login novamente.', {
            redirectTo: 'login',
          });
        } else {
          this.createToast(errorMessage);
        }
      } else {
        errorMessage =
          'Ocorreu um erro ao buscar as ofertas. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379';
        this.createToast(errorMessage);
        Sentry.captureException(err);
      }
    } finally {
      this.finishLoading();
    }
  },
  mounted() {
    this.clarity()
    this.calculatePlansHeight();
  },
  methods: {
    numberToCurrency: Utils.numberToCurrency,
    currencyToNumber: Utils.currencyToNumber,
    calculatePlansHeight() {
      const planInfoElement = document.querySelector('.plan-info');
      if (planInfoElement) {
        this.plansHeight['--plans-height'] = `${planInfoElement.offsetHeight}px`;
      }
    },
    validateBankSlipForm() {
      const { fullName, cpf, zipCode, street, number, discountCoupon } = this.form;
      const inputRadioPlans = document.getElementsByName('plan');
      const inputRadioPlansSelected = Array.from(inputRadioPlans).filter(i => i.checked && i);
      const result = {
        isValid: false,
      };

      if (discountCoupon.value !== '' && !this.discountApplied) {
        result.error = {
          field: 'discountCoupon',
          message: 'Cupom não aplicado',
        };

        return result;
      }

      if (!fullName.value || !fullName.isValid) {
        result.error = {
          field: 'fullName',
          message: 'Nome inválido',
        };

        return result;
      }

      if (!cpf.value || !cpf.isValid || !Utils.validateCPF(cpf.value)) {
        result.error = {
          field: 'cpf',
          message: 'CPF inválido',
        };

        return result;
      }

      if (!zipCode.value || !zipCode.isValid) {
        result.error = {
          field: 'zipCode',
          message: 'CEP inválido',
        };

        return result;
      }

      if (!street.value || !street.isValid) {
        result.error = {
          field: 'street',
          message: 'Endereço inválido',
        };

        return result;
      }

      if (number.isValid !== undefined && !number.isValid) {
        result.error = {
          field: 'number',
          message: 'Número do endereço inválido. Não é permitido caracteres especiais.',
        };

        return result;
      }

      if (inputRadioPlansSelected.length === 0) {
        result.error = {
          field: 'Planos',
          message: 'Plano não selecionado',
        };

        return result;
      }

      result.isValid = true;

      return result;
    },
    getPlanOfferByPlan(planId) {
      let planOffer = null;

      for (let key in this.planOffers.offers) {
        let offer = this.planOffers.offers[key];

        if (offer.options.find(planOption => planOption.id === planId)) {
          planOffer = offer;
        }
      }

      return planOffer || this.planOffers.offers['default'];
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth < 600) this.scrollToPlan('auto');
    },
    scrollToPlan(behavior) {
      const plansElement = document.querySelector(`#${this.form.plan.value}`);

      if (plansElement) {
        const planCardClasses = plansElement.labels[0].classList.value;
        let amount = this.windowWidth;

        if (/first/.test(planCardClasses)) amount *= -1;

        document.querySelector('.plans-container').scrollTo({ left: amount, behavior });
      }
    },
    applyDiscountCoupon(discountCoupon) {
      if (!discountCoupon) return;

      this.form.discountCoupon.value = discountCoupon;

      this.initLoading();

      OfferService.validateCoupon(
        `Bearer ${UserSessionService.getAuthenticationToken()}`,
        discountCoupon,
        this.userInfo.id,
      )
        .then(response => {
          for (let key in this.planOffer.options) {
            this.planOffer.options[key].price.discountPercentage = response.data.discountPercentage;
            // Calculo do desconto
            let discountedPlan =
              this.planOffer.options[key].price.original * ((100 - response.data.discountPercentage) / 100);
            discountedPlan = +discountedPlan.toFixed(2);
            this.planOffer.options[key].price.discount = discountedPlan;
          }

          if (response.data.discountPercentage === 0 || !response.data.discountPercentage) {
            this.form.discountCoupon.isValid = false;
            this.discountApplied = false;

            document.querySelector('.discount-coupon').classList.add('form-input-error');
            document.querySelector('.discount-coupon-info').classList.add('discount-coupon-invalid');
            document.querySelector('.discount-coupon-info').textContent = `Esse não é um cupom de desconto`;
          } else {
            this.form.discountCoupon.isValid = true;
            this.form.discountCoupon.disabled = true;
            this.discountPercentage = `-${response.data.discountPercentage}%`;
            this.discountApplied = true;
            this.discountCouponValue = discountCoupon;
            this.couponDiscountApplied = true;


            document.querySelector('input[name=discount-coupon]').classList.add('formDiscountApplied');
            document.querySelector('.discount-coupon-info').classList.add('discount-coupon-info-applied');
            document.querySelector(
              '.discount-coupon-info',
              ).textContent = `Cupom aplicado - ${response.data.discountPercentage}% de desconto`;
            document.querySelector('.summary-price span:last-child').classList.add('with-coupon-discount-applied');

            document.querySelector('.discount-coupon-info').classList.remove('discount-coupon-invalid');
            document.querySelector('.discount-coupon').classList.remove('form-input-error');

            this.trackSnowplow('sendSnowplowSubscribeEvent', {
              form_element: this.form.discountCoupon,
              step_name: 'user_coupon',
              step_num: 6,
              step_value: discountCoupon,
            });
          }
        })
        .catch(error => {
          const statusCode = error.response.status;

          if (error.response) {
            error = error.response.data;
          }

          const errorMessage =
            error.mensagem ||
            error.message ||
            'Ocorreu um erro ao validar o cupom. Tente novamente mais tarde ou entre em contato pelo número +55(21) 97376-4379';

          if (statusCode === 401 || statusCode === 403) {
            UserSessionService.removeData();
            this.finishLoading();
            this.createToast(errorMessage, {
              redirectTo: 'login',
            });
          } else if (statusCode === 422 && error.code.includes('COUPON_')) {
            document.querySelector('#discount-coupon').value = '';
            this.form.discountCoupon.value = '';
            this.createToast(errorMessage);
          } else {
            this.createToast(errorMessage);
          }
        })
        .finally(() => {
          this.finishLoading();
        });
    },
    removeDiscountApplied(formElement) {
      this.form.discountCoupon.value = '';
      this.form.discountCoupon.disabled = false;
      this.discountApplied = false;
      this.discountPercentage = false;
      this.couponDiscountApplied = false;

      document.querySelector('input[name=discount-coupon]').classList.remove('formDiscountApplied');
      document.querySelector('.discount-coupon-info').classList.remove('discount-coupon-info-applied');
      document.querySelector('.discount-coupon-info').classList.remove('discount-coupon-invalid');
      document.querySelector('.discount-coupon-info').textContent =
        'Desconto não acumulativo. Ao aplicar valerá como o principal.';
      document.querySelector('.summary-price span:last-child').classList.remove('with-coupon-discount-applied');

      this.trackSnowplow('sendSnowplowSubscribeEvent', {
        form_element: formElement,
        step_name: 'user_coupon',
        step_num: 6,
      });
    },
    async fillAddress(formElement) {
      if (!this.form.zipCode.value) {
        return;
      }
      try {
        this.trackSnowplow('sendSnowplowSubscribeEvent', {
          form_element: formElement,
          step_name: 'address_zip_code',
          step_num: 13,
        });

        const { data: zipCodeData } = await ZipCodeService.getZipCodeInfo(this.form.zipCode.value);
        if (zipCodeData && zipCodeData.address) {
          this.form.street.value = zipCodeData.address;
          this.form.street.isValid = true;
          document.getElementsByName('street')[0].value = zipCodeData.address; // TODO: ver uma melhor maneira de atualizar o valor do filho
        } else {
          this.form.street.isValid = false;
        }
      } catch (error) {
        if (error.response) {
          error = error.response.data.error;
        }

        let errorMessage = error.message || error.mensagem;

        await this.createToast(errorMessage);
      }
    },

    async getRecaptchaEnterpriseToken() {
      return new Promise((resolve, reject) => {
        grecaptcha.enterprise.ready(() => {
          grecaptcha.enterprise.execute('6Lf2PlEnAAAAAPupTQNC4fAXZDCbGk7gm9Wu4_Nn', { action: 'CHECKOUT' }).then((token) => {
            if (token) {
              resolve(token);
            } else {
              reject('Falha na geração do token reCAPTCHA');
            }
          });
        });
      });
    },

    async submitSubscription(event) {
      try {
        event.preventDefault();

        this.initLoading();

        const formValidation = this.validateBankSlipForm();
        if (!formValidation.isValid) {
          this.finishLoading();
          this.createToast(formValidation.error.message);
          return;
        }

        const recaptchaToken = await this.getRecaptchaEnterpriseToken();

        const preAuthorizationInfo = this.getPreAuthorizationSubscriptionBodyRequest();
        const preAuthorizationResult = await SubscriptionService.submitPreAuthorizationSubscription(
          preAuthorizationInfo, recaptchaToken
        );

        const activateSubscriptionInfo = this.getActivateSubscriptionBodyRequest(
          preAuthorizationInfo,
          preAuthorizationResult,
        );
        const subscriptionCreated = await SubscriptionService.submitActivateSubscription(activateSubscriptionInfo);

        const { data } = subscriptionCreated.data;
        let bankSlip = this.getBankSlip(data);
        let pix = this.getPix(data);
        if (!bankSlip || !bankSlip.digitableLine) {
          throw new Error(
            `Ocorreu uma falha na geração do boleto. Entre em contato com o suporte pelo Whatsapp ${constants.WHATSAPP_PHONE}`,
          );
        }
        this.storeSubscriptionData(bankSlip, pix);

        this.sendSuccessfulSubscriptionEvent();
        await SubscriptionService.changeUserStateToSubscriber();

        this.trackBrazeWhenSuccessPay();

        this.goToBankSlipCongratulationsScreen();
      } catch (error) {
        this.sendSubscriptionFailureEvent();

        if (error.response) {
          error = error.response.data;
        }

        const errorMessage = error.message || error.mensagem;

        if (errorMessage && error.response && (error.response.status === 401 || error.response.status === 403)) {
          UserSessionService.removeData();
          this.createToast(errorMessage, {
            redirectTo: 'login',
          });
        } else if (errorMessage === 'Network Error') {
          this.createToast(this.languageString.GENERAL_FAILURE_errorTitle);
        } else {
          this.trackBrazeWhenError();

          this.isBankSlipFailure = true;
          this.bankSlipFailureMessage = errorMessage;
        }
      } finally {
        this.finishLoading();
      }
    },
    storeSubscriptionData(bankSlip, pix) {
      const currentPlan = this.planOffer.options.find(planOffer => planOffer.id === this.form.plan.value);
      let price;
      if (this.discountApplied && currentPlan.price.discount) {
        price = currentPlan.price.discount;
      } else {
        price = currentPlan.price.full;
      }

      window.sessionStorage.setItem('planName', currentPlan.name);
      window.sessionStorage.setItem('totalValue', this.numberToCurrency(price));
      window.sessionStorage.setItem('installmentQuantity', null);
      window.sessionStorage.setItem('installmentValue', null);

      window.sessionStorage.setItem('userCpf', this.form.cpf.value);
      window.sessionStorage.setItem('userEmail', this.userInfo.email);
      window.sessionStorage.setItem('digitableLine', bankSlip.digitableLine);
      window.sessionStorage.setItem('pdf', bankSlip.pdf);
      window.sessionStorage.setItem('qrcode', pix.qrcode);
      window.sessionStorage.setItem('qrcodeUrl', pix.qrcode_text);
    },
    setCPF() {
      if (this.userInfo && this.userInfo.cpf) {
        this.form.cpf.value = Utils.maskCPF(this.userInfo.cpf);
        this.form.cpf.isValid = true;
      }
    },
    getPreAuthorizationSubscriptionBodyRequest() {
      const plan_identifier = this.form.plan.value;
      const payable_with = 'bank_slip';
      const custom_variables = [];
      const ClienteIUGU = {
        street: this.form.street.value,
        number: this.form.number.value,
        complement: this.form.complement.value,
        zip_code: this.form.zipCode.value,
        email: this.userInfo.email,
        name: this.form.fullName.value,
        cpf_cnpj: Utils.sanitizeDigitsString(this.form.cpf.value),
      };

      if (!ClienteIUGU.number.trim()) {
        ClienteIUGU.number = 'S/N';
      }

      if (this.discountApplied && this.form.discountCoupon) {
        custom_variables.push({
          name: 'cupom_desconto',
          value: this.form.discountCoupon.value,
        });
      }
      return {
        plan_identifier,
        payable_with,
        custom_variables,
        ClienteIUGU,
      };
    },
    getActivateSubscriptionBodyRequest(preAuthorizationInfo, preAuthorizationResult) {
      const { custom_variables } = preAuthorizationInfo;
      const { subscriptionId } = preAuthorizationResult.data.data;
      return {
        subscriptionId,
        custom_variables,
      };
    },
    getBankSlip(data) {
      const { bankSlip } = data.subscription.payment;
      if (bankSlip) {
        return bankSlip;
      } else {
        return undefined;
      }
    },
    getPix(data) {
      const { pix } = data.subscription.payment;
      if (pix) {
        return pix;
      } else {
        return undefined;
      }
    },
    sendSuccessfulSubscriptionEvent() {
      this.trackSnowplow('trackSubscribe', {
        step_name: 'subscribe_success',
        step_num: 17,
        step_value: 'bank_slip',
        failure_reason: null,
      });

      this.trackBraze('trackEvent', {
        name: 'subscribe_success',
        attribute: 'step_name',
        event_name: 'subscribe',
      });
    },
    sendSubscriptionFailureEvent() {
      this.trackGTM('trackSubscribe', {
        event: 'naoCompletouAssinatura',
        category: 'click',
        label: 'naoCompletouAssinatura',
      });
    },
    goToBankSlipCongratulationsScreen() {
      this.navigateToRoute({
        name: 'congrats',
        query: { stepName: 'paymentBankSlip' },
      });
    },
    displayBankSlipPaymentCheckout() {
      this.isBankSlipFailure = false;
    },
    trackBrazeWhenLoadPage() {
      this.trackBraze('trackEvent', {
        name: 'iniciou_checkout',
        attribute: 'step_name',
        event_name: 'checkout',
      });

      this.trackBraze('trackEvent', {
        name: 'checkout_boleto',
        attribute: 'step_name',
        event_name: 'checkout',
      });
    },
    trackBrazeWhenSuccessPay() {
      this.trackBraze('trackEvent', {
        name: 'finalizou_checkout',
        attribute: 'step_name',
        event_name: 'checkout',
      });
    },
    trackBrazeWhenError(erro) {
      this.trackBraze('trackEvent', {
        name: 'erro_pagamento_checkout_boleto',
        attribute: 'step_name',
        event_name: 'checkout',
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./checkout-bank-slip.scss" />
